
import './App.css';
import { BrowserRouter as Router, Link } from 'react-router-dom'

const navbarHeight = 68;

function scrollToSection(id, closeNav) {
    if (id === 'home') {
        document.documentElement.scrollTop = 0
    } else {
        const element = document.getElementById(id)

        if (element === null) return

        const elementPos = element.getBoundingClientRect().top
        const home = document.getElementById('home')
        const homePos = home.getBoundingClientRect().top

        const scrollY = -homePos + elementPos - navbarHeight
        window.scrollTo(0, scrollY)
    }

    closeNav()
}

function HomeMarker() {
    return (
        <div id="home" className="home-marker"></div>
    )
}

function Navbar() {
    const closeNav = () => {
        const navbarCollapse = document.getElementById("navbarCollapse")
        navbarCollapse.classList.remove('show')
    }

    return (
        <nav className="navbar navbar-expand-md navbar-dark navbar-custom fixed-top bg-dark">
            <div className="container navbar-items-wrapper">
                <Link className="navbar-main-link" to="/">yoko-kirkland.com</Link>
                <button
                    className={`navbar-toggler`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarCollapse"
                    aria-controls="navbarCollapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`navbar-collapse navbar-collapse-custom collapse navbar-collapse-justify-right`} id="navbarCollapse">
                    <ul className="navbar-nav navbar-nav-custom me-auto mb-0 mb-md-0 nav-1">
                        <li className="nav-item">
                            <Link className="nav-link" to='/' onClick={() => scrollToSection('home', closeNav)}>ホーム</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to='/' onClick={() => scrollToSection('message', closeNav)}>メッセージ</Link>
                        </li>
                        <li className="nav-item dropdown otherNavItems-dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                もっと見る
                            </a>
                            <ul className="dropdown-menu dropdown-menu-custom" aria-labelledby="navbarDropdown">
                                <li className="nav-item">
                                    <Link className="nav-link" to='/' onClick={() => scrollToSection('policy', closeNav)}>政策</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to='/' onClick={() => scrollToSection('profile', closeNav)}>新城への思い</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to='/' onClick={() => scrollToSection('personality', closeNav)}>好きなこと</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to='/' onClick={() => scrollToSection('contact', closeNav)}>コンタクト</Link>
                                </li>
                            </ul>
                        </li>

                    </ul>
                    <ul className="navbar-nav otherNavItems-normal me-auto mb-2 mb-md-0 nav-2">
                        <li className="nav-item">
                            <Link className="nav-link" to='/' onClick={() => scrollToSection('policy', closeNav)}>政策</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to='/' onClick={() => scrollToSection('profile', closeNav)}>新城への思い</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to='/' onClick={() => scrollToSection('personality', closeNav)}>好きなこと</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to='/' onClick={() => scrollToSection('contact', closeNav)}>コンタクト</Link>
                        </li>
                    </ul>
                    <hr className="navbar-divider" />
                    <ul className="navbar-nav navbar-nav-links me-auto mb-2 mb-md-0 nav-3">
                        <li className="nav-item nav-social">
                            <a className="nav-link nav-social-icon" href="https://www.facebook.com/KirklandYoko" alt="facebook">
                                <i className="bi-facebook navbar-custom-icon facebook" />
                            </a>
                        </li>
                        <li className="nav-item nav-social">
                            <a className="nav-link nav-social-icon" href="https://instagram.com/yokokirkland" alt="e-mail">
                                <i className="bi-instagram navbar-custom-icon" />
                            </a>
                        </li>
                        <li className="nav-item nav-social">
                            <a className="nav-link nav-social-icon" href="mailto:info@yoko-kirkland.com" alt="e-mail">
                                <i className="bi-envelope navbar-custom-icon mail" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

function Header() {
    return (
        <header className="d-flex flex-wrap justify-content-center custom-header">
            <Navbar />
            <div className="site-header-wrapper">
                <img src="/images/site-header.jpg" className="site-header-image" alt="" />
            </div>
        </header>
    )
}

function Reports() {
    return (
        <div className="container">
            <div id="message1" className="message-container">
                <div className="row">
                    <div className="col-0 col-lg-2 col-xl-3">
                        &nbsp;
                    </div>
                    <div className="col-12 col-lg-8 col-xl-6 centered-text">
                        <div className="main-text">
                            <span className="section-title message1-title">活動報告と議会報告</span>
                            <span className="message1-p">「柿栗レポート」</span>
                        </div>
                    </div>
                    <div className="col-0 col-lg-2 col-xl-3">
                    </div>
                </div>
                <div className="row h-gutter-xl">
                    <div className="col-12 centered-text flex-center">
                        <div className="link-text h-gutter">
                            <span className="link-p strong"><a href="/docs/report-oct-22/柿栗レポート　VOL 4 PDF.pdf">「柿栗レポート」 VOL 4</a></span>
                        </div>
                    </div>
                    <div className="col-12 centered-text flex-center">
                        <div className="image-25-percent">
                            <a href="/docs/report-oct-22/柿栗レポート　VOL 4 PDF-1.jpg"><img src="/docs/report-oct-22/柿栗レポート　VOL 4 PDF-1.jpg" /></a>
                        </div>
                        <div className="image-25-percent">
                            <a href="/docs/report-oct-22/柿栗レポート　VOL 4 PDF-2.jpg"><img src="/docs/report-oct-22/柿栗レポート　VOL 4 PDF-2.jpg" /></a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 centered-text">
                        <div className="link-text">
                            <div className="mini-subheading">以前のレポート</div>
                            <span className="link-p"><a href="/docs/report-jul-22/柿栗レポート　VOL 3 PDF.pdf">「柿栗レポート」 VOL 3</a></span>
                            <span className="link-p"><a href="/docs/report-apr-22/柿栗レポート-VOL2.pdf">「柿栗レポート」 VOL 2</a></span>
                            <span className="link-p"><a href="/docs/report-apr-22/柿栗レポート-VOL1.pdf">「柿栗レポート」 VOL 1</a></span>
                        </div>
                    </div>
                    <div className="col-0 col-lg-2 col-xl-3">
                    </div>
                </div>
            </div>
        </div>
    )
}

// function Message1() {
//     return (
//         <div className="container">
//             <div id="message1" className="message-container">
//                 <div className="row">
//                     <div className="col-0 col-lg-2 col-xl-3">
//                         &nbsp;
//                     </div>
//                     <div className="col-12 col-lg-8 col-xl-6 centered-text">
//                         <div className="main-text">
//                             <span className="section-title message1-title">活動報告と議会報告</span>
//                             <span className="message1-p">「柿栗レポート」</span>
//                         </div>
//                     </div>
//                     <div className="col-0 col-lg-2 col-xl-3">
//                     </div>
//                 </div>
//                 <div className="row">
//                     <div className="col-12 centered-text">
//                         <div className="link-text">
//                             <span className="link-p"><a href="/docs/report-apr-22/柿栗レポート-VOL1.pdf">柿栗レポート VOL 1</a></span>
//                             <span className="link-p"><a href="/docs/report-apr-22/柿栗レポート-VOL2.pdf">柿栗レポート VOL 2</a></span>
//                         </div>
//                     </div>
//                     <div className="col-0 col-lg-2 col-xl-3">
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

function Message0() {
    return (
        <div className="container">
            <div id="message0" className="message-container">


                <div className="row">
                    <div className="col-0 col-lg-2 col-xl-3">
                        &nbsp;
                    </div>
                    <div className="col-12 col-lg-8 col-xl-6 centered-text">
                        <div className="main-text">
                            <span className="section-title message0-title">年賀状に関するお知らせ</span>
                            <span className="message0-p">政治家が有権者に年賀状や暑中見舞い状などの時候の挨拶状を出すのは、公職選挙法によって禁止されております。これは、お金をかけた行為によって選挙に有利になることを防ぐための制度です。なので、私からは年賀状を控えさせていただきますので、皆様のご理解の程、よろしくお願いいたします。</span>
                            <span className="message0-p">また、以下の寄付行為も同様の理由により、禁止されております。</span>
                            <span className="message0-p">いつも応援してくださる皆様にお礼をしたい気持ちは山々なのですが、市議としての責務をしっかりと果たすことによってお礼に変えさせていただきたいと思っておりますので、ご理解いただけると幸いです。🙇‍♀️</span>
                        </div>
                    </div>
                    <div className="col-0 col-lg-2 col-xl-3">
                    </div>
                </div>
                <div className="row">
                    <div className="col-0 col-lg-2 col-xl-3">
                        &nbsp;
                    </div>
                    <div className="col-12 col-lg-8 col-xl-6 centered-text">
                        <img className="full-width" src="/images/notice-bribes.png" alt="" />
                        <div className="link-text">
                            <span className="link-p"><a href="https://www.soumu.go.jp/senkyo/senkyo_s/naruhodo/naruhodo08.html">出典: 総務省ホームページ</a></span>
                        </div>
                    </div>
                    <div className="col-0 col-lg-2 col-xl-3">
                    </div>
                </div>
            </div>
        </div>
    )
}

function Message() {
    return (
        <div className="container">
            <div id="message" className="message-container">

                <div className="message-text">
                    <span className="section-title message-title">メッセージ</span>
                    尊敬する父や大好きだった祖父母が暮らしたこの街のために何かしたい。<br />
                    母親目線や海外経験を、新城のために活かしたい。<br />
                    新城に住むすべての人が、自分に自信を持って生きることができるように。
                </div>
                <div className="message-img-container">
                    <img src="/images/wind.gif" className="message-img" alt="" />
                    <div className="blur-img-edges"></div>
                </div>
            </div>
        </div>
    )
}

function Policy() {
    return (
        <div id={'policy'} className="container policy-container">
            <div className="policy-title">
                政策
            </div>
            <div className="row policy-wrapper" data-masonry='{"percentPosition": true }'>
                <div className="col-12 col-lg-6 col-xl-4">
                    <div className="policy-item-wrapper">
                        <span className="policy-item">
                            <img src="/images/circled-green-tick.gif" className="policy-tick" alt="tick" />
                            「自分で考える教育」で、一人一人が自信を持てる町
                        </span>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4">
                    <div className="policy-item-wrapper">
                        <span className="policy-item">
                            <img src="/images/circled-green-tick.gif" className="policy-tick" alt="tick" />
                            伝統や歴史を活かすまちづくり
                        </span>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4">
                    <div className="policy-item-wrapper">
                        <span className="policy-item">
                            <img src="/images/circled-green-tick.gif" className="policy-tick" alt="tick" />
                            環境にやさしい農林業を応援し、まちづくりに活かします
                        </span>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4">
                    <div className="policy-item-wrapper">
                        <span className="policy-item">
                            <img src="/images/circled-green-tick.gif" className="policy-tick" alt="tick" />
                            適材適所で、一人一人の個性が活かされる町
                        </span>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4">
                    <div className="policy-item-wrapper">

                        <span className="policy-item">
                            <img src="/images/circled-green-tick.gif" className="policy-tick" alt="tick" />
                            外国籍の人たちが日本語や日本の文化・習慣をしっかり理解し、上手に共生できる町
                        </span>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4">
                    <div className="policy-item-wrapper">

                        <span className="policy-item">
                            <img src="/images/circled-green-tick.gif" className="policy-tick" alt="tick" />
                            私たちの税金が、適切に活かされる町
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Profile() {
    return (
        <div id="profile" className="profile-wrapper">
            <div className="profile-banner-wrapper">
                <img className="profile-banner" src="/images/kaki-closeup.jpg" alt="" />
            </div>
            <div className="container profile-container">
                <div className="section-title profile-title">
                    新城への思い
                </div>
                <div className="profile-text">
                    <p>
                        私は千葉県で生まれ育ちましたが、父親が新城市西新町の出身で、祖父母が西新町で柿田医院と言う医院をやっておりました。生まれて７ヶ月くらいの時に母が病気をし、入院したため、私は０才〜１才にかけての約７ヶ月間、新城の祖父母のもとに預けられました。その影響もあってか、その後も私は本当に新城が大好きでした。遊びにくれば祖母が私を連れ歩き、ご近所さんに「あの時の赤ちゃんだよ」と言う感じで紹介してくれました。
                    </p>
                    <div className="profile-divider"></div>
                    <p>
                        夏になると毎年家族で新城に遊びに来て、当時はまだ遊泳が禁止されていなかった桜淵で泳いだりして毎日遊び、休みが終わる頃には真っ黒に日焼けしていたものです。私が１９才の時に、大好きだった祖母が亡くなってからは新城を訪れる頻度は２〜３年に１度と減ってしまいましたがそれでも、中宇利にある祖父母のお墓に参ったり西新町の家を掃除するために、定期的に新城には遊びに来ていました。
                    </p>
                    <div className="profile-divider"></div>
                    <p>
                        しかし、訪れるたびに、思い出がいっぱいのお店などが次々と消え、来る度に寂しくなっていく新城を、とても悲しい気持ちで眺めていました。そして次第に、私も新城のために何か役に立ちたいと言う思いを強く抱くようになりました。そして<span className="no-break">２０１５年、</span>１０年間住んでいたイギリスのロンドンから主人と娘と３人で、新城に引っ越す決意をいたしました。
                    </p>
                    <div className="profile-divider"></div>
                    <p>
                        当初は、新城市内のビジネスや情報などを一括して見ることができるようなシステムを、ITプログラマーである主人と一緒に作り、新城の活性化に役に立てたいと思いスタートしたのですが、初めてのことでうまくスタートに至らせることができず、それからは、来たお話は全てお受けすると言う姿勢で、少しでも自分にできることをしようと思い、ニューキャッスルアライアンス実行委員や英語関連のボランティアなど、地域活動に参加させていただいておりました。
                    </p>
                    <div className="profile-divider"></div>
                    <p>
                        市外・国外に住んだことがある私から見ると、新城は素晴らしいものを沢山持っています。けれど今の時点では、それらがしっかり活かされているとは言い難い、むしろどんどん消えていっていると思います。新城が持っているものをしっかり活かし、新城に住む人みんなが新城市民であることに自信を持てるような街づくりのお手伝いをしたいと思っています。
                    </p>
                </div>
            </div>
        </div>
    )
}



function Personality() {
    return (
        <div id={'personality'} className="container personality-wrapper">
            <div className="personality-title">
                好きなこと
            </div>
            <div className="row">
                <div className="col-12 col-md-4 order-1 order-md-2">
                    <div className="yoko-image-wrapper">
                        <img className="yoko-to-usagi" src="/images/yoko-to-usagi.jpg" alt="陽子と兎" />
                    </div>
                </div>
                <div className="col-6 col-md-4 order-2 order-md-1">
                    <div className="row likes likes-left">
                        <div className="col-12 heart-top">
                            <div className="personal-like">
                                <img src="/images/heart-1.gif" className="like-heart" alt="" /><br />水泳
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="personal-like">
                                <img src="/images/heart-4.gif" className="like-heart" alt="" /><br />旅行
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-4 order-3 order-md-3">
                    <div className="row likes likes-right">
                        <div className="col-12 heart-top">
                            <div className="personal-like">
                                <img src="/images/heart-3.gif" className="like-heart" alt="" /><br />歌うこと
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="personal-like">
                                <img src="/images/heart-2.gif" className="like-heart" alt="" /><br /><span className="no-break">ウサギと遊ぶこと</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Contact() {
    return (
        <div id={'contact'} className="container">
            <div className="row contact-wrapper">
                <div className="col-12 order-1 col-lg-5 order-lg-3 contact-col contact-details">
                    <div className="contact-title">
                        コンタクト
                    </div>
                    <div className="contact-details">
                        <div className="contact-row emphasis">カークランド陽子後援会事務所</div>
                        <div className="contact-row mb-extra">〒441-1373 <span className="no-break">愛知県新城市字西新町 29-2</span></div>
                        <div className="contact-row"><span className="contact-icon"><i className="bi-envelope" /></span> info@yoko-kirkland.com</div>
                        <div className="contact-row"><span className="contact-icon"><i className="bi-telephone" /></span> 080–9280–9605</div>
                        {/* <div className="contact-row"><span className="contact-icon"><i className="bi-printer" /></span> 052–687–4670</div> */}
                    </div>
                </div>
                <div className="col-0 order-3 col-lg-1 order-lg-2"></div>
                <div className="col-12 order-2 col-lg-5 order-lg-1 contact-col">
                    <img src="/images/yoko-goal-speech.png" className="img-yoko-speech" alt="カークランド陽子" />
                </div>
            </div>
        </div>
    )
}

function Footer() {
    return (
        <div className="footer-wrapper">
            <div className="footer-content">
                <div className="footer-1">
                    <div className="footer-details">
                        <div className="footer-row emphasis">カークランド陽子後援会事務所</div>
                        <div className="footer-row mb-extra">〒441-1373 <span className="no-break">愛知県新城市字西新町 29-2</span></div>
                        <div className="footer-row"><span className="footer-icon"><i className="bi-envelope" /></span> info@yoko-kirkland.com</div>
                        <div className="footer-row"><span className="footer-icon"><i className="bi-telephone" /></span> 080–9280–9605</div>
                        {/* <div className="footer-row"><span className="footer-icon"><i className="bi-printer" /></span> 052–687–4670</div> */}
                    </div>
                </div>
                <div className="footer-2">
                    <div className="map-wrapper">
                        <img src="/images/map.jpg" className="map-img" alt="map" />
                    </div>
                </div>
                {/* <div className="footer-3"></div> */}
            </div>
        </div>
    )
}

function App() {
    return (
        <Router>
            <div className="app">
                <HomeMarker />
                <Header />
                <Reports />
                <Message0 />
                <Message />
                <Policy />
                <Profile />
                <Personality />
                <Contact />
                <Footer />
            </div>
        </Router>

    );
}

export default App;
